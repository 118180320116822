import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import CryptoIllustration from "../../../svg/ComparisonResultPageIllustrations/cryptocurrencyservices.svg";
import CoinBaseSVG from "../../../svg/ComparisonResultGridLogos/cryptoservices/coinbase.svg";
import TenXSVG from "../../../svg/ComparisonResultGridLogos/cryptoservices/tenx.svg";
import XcoinsSVG from "../../../svg/ComparisonResultGridLogos/cryptoservices/xcoins.svg";
import CryptoComSVG from "../../../svg/ComparisonResultGridLogos/cryptoservices/crypto.svg";
import WirexSVG from "../../../svg/ComparisonResultGridLogos/cryptoservices/wirex.svg";
export const pageMetaData = {
  pageTitle: "Compare Crypto Currency Services",
  pageDescription: "Find The Right Service With Fees As Low As $3.25",
  pageImagePath: "/cryptocurrencyservices.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Fees"
      }, {
        index: 2,
        text: "Supported Crypto Currencies"
      }, {
        index: 3,
        text: "Platforms"
      }, {
        index: 4,
        text: "Pros"
      }, {
        index: 5,
        text: "Cons"
      }, {
        index: 6,
        text: "Rewards"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Coinbase",
          subText: "",
          imageKey: "coinbase"
        }
      }, {
        index: 2,
        text: "1.49% - 3.99%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showRating: true,
        showSavingText: true,
        rating: 4
      }, {
        index: 3,
        usePopover: true,
        popOverTitle: "Supported Crypto Currencies",
        popOverContents: "Bitcoin (BTC), Ethereum (ETH), Ripple (XRP), Litecoin (LTC), Bitcoin Cash (BCH), EOS (EOS), Stellar Lumens (XLM), Ethereum Classic (ETC), Zcash (ZEC), Augur (REP), Dai (DAI), USD Coin (USDC), 0x (ZRX), Basic Attention Token (BAT), Algorand (Algo), Cosmos (Atom), Civic (CVC), Dash (DASH), District0x (DNT), Golem (GNT), Kyber Network (KNC), Chainlink (LINK), Loom Network (LOOM), Decentraland (MANA), Maker (MKR), Orchid (OXT), Tezos (XTZ)"
      }, {
        index: 4,
        text: "Web, Android, iOS"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Coinbase Pro is one of the top exchanges in the market as its high liquidity and limits have endeared it to traders executing large trades. Good reputation, security, high trading volume, easy to use, reasonable fees, beginner friendly, stored currency is covered by Coinbase insurance. It’s easy for novice users to trade and buy bitcoin since it allows fiat currency trading."
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Customer support, limited payment methods, limited countries supported, non-uniform rollout of services worldwide, GDAX suitable for technical traders only."
      }, {
        index: 7,
        text: "Earn $121 of currency by completing courses"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://coinbase-consumer.sjv.io/c/1323808/564368/9251",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Coinbase",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "TenX",
          subText: "",
          imageKey: "tenx"
        }
      }, {
        index: 2,
        text: "3.25 - 15",
        isPriceCell: true,
        showSavingText: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        usePopover: true,
        popOverTitle: "Supported Crypto Currencies",
        popOverContents: "BTC, ETH, LTC"
      }, {
        index: 4,
        text: "Android, iOS"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "The TenX platform is looking to make cryptocurrency usage as simple and straightforward as possible, built on the back of the Comit Network. The ability to help users make direct payment using crypto assets (but allow businesses to receive funds in a preferred fiat) is likely to attract more users"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "It needs to be stated that the lack of availability is a substantial disadvantage. If the card isn’t available, we can’t really review it properly. So until TenX fixes that, users will be left disappointed."
      }, {
        index: 7,
        text: "0.1% reward in the form of PAY tokens"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://tenx.tech/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to TenX",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Xcoins",
          subText: "",
          imageKey: "xcoins"
        }
      }, {
        index: 2,
        text: "From 2.9%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        usePopover: true,
        popOverTitle: "Supported Crypto Currencies",
        popOverContents: "BTC, ETH, LTC, XRP, BTC"
      }, {
        index: 4,
        text: "Android, iOS"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "24/7 live support.|Low fees.|Simple & secure registration and verification.|Quick processing and transaction processes.|Available worldwide"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Only offers BTC, ETH, LTC, XRP, BTC Cash"
      }, {
        index: 7,
        text: "8% - 12% p.a. On Holdings"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://xcoins.io/r",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Xcoins",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Crypto.com",
          subText: "",
          imageKey: "crypto-com"
        }
      }, {
        index: 2,
        text: "0%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        usePopover: true,
        popOverTitle: "Supported Crypto Currencies",
        popOverContents: "53 Cryptocurrencies (including BTC, ETH, XRP, LTC, MCO & CRO)"
      }, {
        index: 4,
        text: "Android, iOS"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Complete crypto platform.|High speed exchange with competitive fees.|Frequent promotions.|Lets you earn returns on deposits.|Crypto-powered rewards card"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Getting the most out of it requires MCO or CRO staking.|It has a convoluted two token system.|Lack of transparency in where its funding comes from"
      }, {
        index: 7,
        text: "8% - 12% p.a. On Holdings"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://crypto.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Crypto.com",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Wirex",
          subText: "",
          imageKey: "wirex"
        }
      }, {
        index: 2,
        text: "1.50",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        usePopover: true,
        popOverTitle: "Supported Crypto Currencies",
        popOverContents: "BTC, LTC, XRP, ETH, WXT, XLM, DAI"
      }, {
        index: 4,
        text: "Web, Android, iOS"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "It’s the only card provider that offers Bitcoin rewards.|Very low fees.|Easy to use and set up.|Visa, accepted everywhere.|Contactless and nice designed card.|People always asked about it and were blown away by the rewards"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "App could do with some UX tweaks.|Only available in Europe and UK.|Security can be a bit over the top and frustrating"
      }, {
        index: 7,
        text: "1.5% BTC"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://wirex.sjv.io/c/1323808/660088/8373",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to Wirex",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare crypto currency services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<CryptoIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="CryptoIllustration" />, <CryptoIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="CryptoIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <CoinBaseSVG className="w-full" imagekey="coinbase" mdxType="CoinBaseSVG" />
  <TenXSVG className="w-full" imagekey="tenx" mdxType="TenXSVG" />
  <XcoinsSVG className="w-full" imagekey="xcoins" mdxType="XcoinsSVG" />
  <CryptoComSVG className="w-full" imagekey="crypto-com" mdxType="CryptoComSVG" />
  <WirexSVG className="w-full" imagekey="wirex" mdxType="WirexSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="financial" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="cryptocurrency services" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>


    <p>{`;`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      